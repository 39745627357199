<template>
  <v-dialog v-model="show_dialog" width="600" persistent>
    <v-card width="600px">
      <v-container class="pa-4">
        <h1>Edit Stripe Credentials</h1>
        <p class="mb-4">
          Please be completly sure what you are about to update. Inputing wrong
          deatils here might result in shop being unable to transact.
        </p>

        <v-form v-model="is_valid">
          <v-text-field
            color="primary"
            label="Stripe Private Key"
            v-model="private_key"
            required
            :rules="[field_req, starts_with_sk_]"
          />
          <v-text-field
            color="primary"
            label="Stripe Public Key"
            v-model="public_key"
            required
            :rules="[field_req, starts_with_pk_]"
          />
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined rounded @click="is_shown = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="mr-0"
            :disabled="!is_valid"
            @click="update_credentials"
          >
            Update Unify Credentials
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/db";

import { field_req } from "@/utils/form_val_rules";

function starts_with_sk_(v) {
  if (!v) return false;
  return v.startsWith("sk_") || "Priavate key must start with 'sk_'";
}

function starts_with_pk_(v) {
  if (!v) return false;
  return v.startsWith("pk_") || "Priavate key must start with 'pk_'";
}

export default {
  name: "StripeCredentials",
  data() {
    return {
      is_valid: false,
      private_key: null,
      public_key: null,
      field_req,
      starts_with_sk_,
      starts_with_pk_,
    };
  },
  props: {
    credentials: {
      type: Object,
      deafult: null,
    },
    show_dialog: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.private_key = this.credentials.private_key;
    this.public_key = this.credentials.public_key;
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    is_shown: {
      set(v) {
        if (!v) this.$emit("close");
      },
      get() {
        return this.show_dialog;
      },
    },
  },
  methods: {
    async update_credentials() {
      await db
        .collection("shops")
        .doc(this.shop_id)
        .collection("admin_data")
        .doc("stripe-private-key")
        .update({
          key: this.private_key,
        });
      await db
        .collection("shops")
        .doc(this.shop_id)
        .collection("admin_data")
        .doc("stripe-public-key")
        .update({
          key: this.public_key,
        });
      this.is_shown = false;
    },
  },
};
</script>
