<template>
  <v-dialog v-model="show_dialog" width="600" persistent>
    <v-card width="600px">
      <v-container class="pa-4">
        <h1>Edit Payji Point Of Sale</h1>
        <p class="mb-4">
          Please be completly sure what you are about to update. Inputing wrong
          deatils here might result in shop being unable to transact, or worst
          money going to wrong shop.
        </p>

        <v-form v-model="is_valid">
          <v-text-field
            v-model.number="current_pos_id"
            label="Payji Marketplace POS ID"
            color="primary"
            required
            :rules="[field_req]"
          ></v-text-field>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined rounded @click="is_shown = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="mr-0"
            :disabled="!is_valid"
            @click="update_credentials"
          >
            Update Payji POS
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/db";

import { field_req } from "@/utils/form_val_rules";

export default {
  name: "PayjiPointOfSale",
  data() {
    return {
      current_pos_id: null,
      is_valid: false,
      field_req,
    };
  },
  props: {
    payjiPosId: { type: Number, required: true },
    show_dialog: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.current_pos_id = this.payjiPosId;
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    is_shown: {
      set(v) {
        if (!v) this.$emit("close");
      },
      get() {
        return this.show_dialog;
      },
    },
  },
  methods: {
    async update_credentials() {
      await db.collection("shops").doc(this.shop_id).update({
        payji_pos_id: this.current_pos_id,
      });
      this.is_shown = false;
    },
  },
};
</script>
