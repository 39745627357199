<template>
  <v-dialog v-model="show_dialog" width="600" persistent>
    <v-card width="600px">
      <v-container class="pa-4">
        <h1>Edit Unify Credentials</h1>
        <p class="mb-4">
          Please be completly sure what you are about to update. Inputing wrong
          deatils here might result in shop being unable to transact.
        </p>

        <v-form v-model="is_valid">
          <v-text-field
            color="primary"
            label="Unify Merchant ID"
            v-model="merchant_id"
            required
            :rules="[field_req]"
          />
          <v-text-field
            color="primary"
            label="Unify Password"
            v-model="password"
            required
            :rules="[field_req]"
          />
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined rounded @click="is_shown = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="mr-0"
            :disabled="!is_valid"
            @click="update_credentials"
          >
            Update Unify Credentials
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/db";

import { field_req } from "@/utils/form_val_rules";

export default {
  name: "UnifyCredentials",
  data() {
    return {
      is_valid: false,
      merchant_id: null,
      password: null,
      field_req,
    };
  },
  props: {
    credentials: {
      type: Object,
      deafult: null,
    },
    show_dialog: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    this.merchant_id = this.credentials.merchant_id;
    this.password = this.credentials.password;
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    is_shown: {
      set(v) {
        if (!v) this.$emit("close");
      },
      get() {
        return this.show_dialog;
      },
    },
  },
  methods: {
    async update_credentials() {
      await db
        .collection("shops")
        .doc(this.shop_id)
        .collection("admin_data")
        .doc("unify-credentials")
        .update({
          merchant_id: this.merchant_id,
          password: this.password,
        });
      this.is_shown = false;
    },
  },
};
</script>
