<template>
  <div outlined class="shops pa-8">
    <div v-if="is_loading" class="loading-overlay">
      <v-progress-circular indeterminate size="30" color="primary" />
    </div>
    <div v-if="$route.params.id && shop">
      <p class="text-h4 mb-4 font-weight-bold ml-4">
        {{ shop.name }} | created on
        <b>{{ moment.unix(shop.created_at.seconds).format("DD/MM/YY") }}</b>
      </p>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="is_super_admin">
              <td>Brands</td>
              <td class="d-flex align-center">
                <p class="mb-0 mr-2">{{ Object.keys(shop.brand).length }}</p>
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="edit_brands = true"
                  >Edit</v-btn
                >
              </td>
            </tr>
            <tr v-if="owner">
              <td>Owner</td>
              <td>
                {{ owner.name }}
                <a v-if="owner" :href="`mailto:${owner.email}`">{{
                  owner.email
                }}</a>
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>Is Shop Directory</td>
              <td class="d-flex align-center">
                <p v-if="!processing_request" class="mb-0 mr-2">
                  {{ is_directory_shop ? "Yes" : "No" }}
                </p>
                <v-progress-circular v-else size="10" indeterminate />
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="toggle_is_directory_shop"
                  >Toggle</v-btn
                >
              </td>
            </tr>
            <tr>
              <td>Payji Point Of Sale ID</td>
              <td class="d-flex align-center">
                <p class="mb-0 mr-2 text-capitalize">
                  {{ shop.payji_pos_id }}
                </p>
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="edit_payji_pos = true"
                  >Edit</v-btn
                >
              </td>
            </tr>
            <tr>
              <td>Payment Processor</td>
              <td class="d-flex align-center">
                <p class="mb-0 mr-2 text-capitalize">
                  {{ shop.payment_processor }}
                </p>
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="edit_payment_processor = true"
                  >Edit</v-btn
                >
              </td>
            </tr>
            <tr>
              <td>Payment Credentials</td>
              <td class="d-flex align-center">
                <p class="mb-0 mr-2">
                  {{ credentials | pretty }}
                </p>
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="edit_credentials = true"
                  >Edit</v-btn
                >
              </td>
            </tr>
            <tr v-if="is_super_admin">
              <td>Top Picks</td>
              <td class="d-flex align-center">
                <p class="mb-0 mr-2">
                  {{ Object.keys(shop.top_picks).length }}
                </p>
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="edit_top_picks = true"
                  >Edit</v-btn
                >
              </td>
            </tr>
            <tr v-if="is_super_admin">
              <td>Shares products with</td>
              <td class="d-flex align-center">
                <p class="mb-0 mr-2">
                  {{
                    shop.shares_products_with
                      ? Object.keys(shop.shares_products_with).join(", ")
                      : "None"
                  }}
                </p>
              </td>
              <td>
                <v-btn
                  small
                  color="black"
                  outlined
                  roudned
                  @click="edit_shares_products_with = true"
                  >Edit</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="text-right mt-4">
        <v-btn color="primary" to="/" rounded class="mr-0"
          ><span class="white--text">Open shop dashboard</span></v-btn
        >
      </div>
      <BrandsAdmin
        v-if="edit_brands"
        :brands="brands"
        :show_dialog="edit_brands"
        :shop_brands="shop.brand"
        @close="edit_brands = false"
      />
      <EditTopPicks
        v-if="edit_top_picks"
        :show_dialog="edit_top_picks"
        @close="edit_top_picks = false"
      />
      <EditSharesProductsWith
        v-if="edit_shares_products_with"
        :show_dialog="edit_shares_products_with"
        @close="edit_shares_products_with = false"
      />
      <PaymentProcessor
        v-if="edit_payment_processor"
        :show_dialog="edit_payment_processor"
        :processor="payment_processor"
        @close="edit_payment_processor = false"
      />
      <PayjiPointOfSale
        v-if="edit_payji_pos"
        :payji-pos-id="shop.payji_pos_id"
        :show_dialog="edit_payji_pos"
        @close="edit_payji_pos = false"
      />
      <UnifyCredentials
        v-if="edit_credentials && payment_processor === 'unify'"
        :show_dialog="edit_credentials"
        :credentials="credentials"
        @close="(edit_credentials = false), get_payment_credentials(shop)"
      />
      <StripeCredentials
        v-if="edit_credentials && payment_processor === 'stripe'"
        :show_dialog="edit_credentials"
        :credentials="credentials"
        @close="(edit_credentials = false), get_payment_credentials(shop)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import { db } from "@/db";
import { update_current_user } from "@/requests";

import BrandsAdmin from "@/views/admin/components/Brands";
import UnifyCredentials from "@/views/admin/components/UnifyCredentials";
import StripeCredentials from "@/views/admin/components/StripeCredentials";
import PaymentProcessor from "@/views/admin/components/PaymentProcessor";
import EditTopPicks from "@/views/admin/components/EditTopPicks";
import EditSharesProductsWith from "@/views/admin/components/EditSharesProductsWith";
import PayjiPointOfSale from "@/views/admin/components/PayjiPointOfSale";

export default {
  name: "AdminShops",
  components: {
    BrandsAdmin,
    StripeCredentials,
    UnifyCredentials,
    PaymentProcessor,
    EditTopPicks,
    EditSharesProductsWith,
    PayjiPointOfSale,
  },
  data() {
    return {
      moment,
      selected_shop: null,
      is_loading: false,
      processing_request: false,
      edit_payji_pos: false,
      edit_brands: false,
      edit_credentials: false,
      edit_payment_processor: false,
      edit_top_picks: false,
      edit_shares_products_with: false,
      stripe_credentials: {},
      unify_credentials: {},
      owner: null,
    };
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(JSON.stringify(value)), null, 2);
    },
  },
  computed: {
    ...mapState("UserStore", ["user"]),
    ...mapState("SuperAdminStore", ["brands"]),
    ...mapState("AdminStore", ["shop_id", "shop", "directory_shops"]),
    ...mapState("UserStore", ["is_super_admin"]),
    ...mapGetters("UserStore", ["name"]),
    is_directory_shop() {
      return this?.directory_shops?.[this?.shop?.id] ?? false;
    },
    payment_processor() {
      if (!this.shop) return;
      return this.shop.payment_processor;
    },
    credentials() {
      if (!this.shop) return;
      const processor = this.payment_processor;
      if (processor === "stripe") return this.stripe_credentials;
      if (processor === "unify") return this.unify_credentials;
      return {};
    },
  },
  watch: {
    shop: async function (shop) {
      if (!shop) return;
      this.get_payment_credentials(shop);
      this.get_shop_owner(shop);
    },
  },
  async mounted() {
    await this.admin_shop(this.$route.params.id);
  },
  methods: {
    ...mapActions("AdminStore", ["bind_admin_data", "unbind_admin_data"]),
    ...mapActions("ShopStore", ["get_shop"]),
    ...mapActions("RealtimeStore", ["connect", "disconnect"]),
    async admin_shop(shop_id) {
      this.is_loading = true;
      await Promise.all([
        this.update_super_admin_shop_id(shop_id),
        this.bind_admin_data({ shop_id: shop_id }),
        this.get_shop(shop_id),
        this.disconnect(),
      ]);
      this.is_loading = false;
      this.connect(shop_id);
    },
    async update_super_admin_shop_id(shop_id) {
      try {
        await update_current_user({ shop_id });
      } catch (error) {
        console.error(error);
      }
    },
    async get_payment_credentials(shop) {
      const processor = shop.payment_processor;
      if (processor === "stripe") {
        let public_key = await db
          .collection("shops")
          .doc(shop.id)
          .collection("admin_data")
          .doc("stripe-public-key")
          .get()
          .then((doc) => (doc.exists ? doc.data().key : "No key present"));

        let private_key = await db
          .collection("shops")
          .doc(shop.id)
          .collection("admin_data")
          .doc("stripe-private-key")
          .get()
          .then((doc) => (doc.exists ? doc.data().key : "No key present"));
        this.stripe_credentials = {
          private_key,
          public_key,
        };
      } else if (processor === "unify") {
        let unify_credentials = await db
          .collection("shops")
          .doc(shop.id)
          .collection("admin_data")
          .doc("unify-credentials")
          .get()
          .then((doc) => (doc.exists ? doc.data() : "No key present"));
        this.unify_credentials = unify_credentials;
      }
    },
    async get_shop_owner(shop) {
      let owner = await db
        .collection("shops")
        .doc(shop.id)
        .collection("admin_data")
        .doc("authentication")
        .get()
        .then((doc) => doc.data());
      this.owner = owner;
    },
    async toggle_is_directory_shop() {
      this.processing_request = true;
      setTimeout(() => {
        this.processing_request = false;
      }, 2000);
      if (this.is_directory_shop) {
        await db.collection("directory_shops").doc(this.shop_id).delete();
      } else {
        await db.collection("directory_shops").doc(this.shop_id).set({});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shops {
  position: relative;
  overflow-y: hidden;
  width: 50vw;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: unset;
  }
  background-color: white;
  border-radius: 10px;
}
.loading-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}
.masked {
  position: relative;
  cursor: pointer;

  &:hover {
    .mask {
      transition: all 0.2s ease-in-out;
      font-weight: 700;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      opacity: 0.7;
    }
  }
  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #696969;
  }
}
</style>
