<template>
  <v-dialog v-model="show_dialog" width="600" persistent>
    <v-card width="600px">
      <v-container class="pa-4">
        <h1 class="mb-4">Edit shares products with</h1>
        <v-form v-model="is_valid">
          <v-combobox
            v-model="selected_shops"
            color="primary"
            :items="shops.filter((s) => s.id !== shop_id)"
            item-text="name"
            item-value="id"
            label="Shops"
            multiple
            clearable
          />
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined rounded @click="is_shown = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="mr-0"
            @click="update_shares_products_with"
          >
            Update Top Picks
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/db";

export default {
  name: "EditTopPicks",
  data() {
    return {
      selected_shops: [],
      is_valid: false,
    };
  },
  props: {
    show_dialog: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    if (this.shop.shares_products_with) {
      const shared_with = Object.keys(this.shop.shares_products_with);
      this.selected_shops = this.shops.filter((s) =>
        shared_with.includes(s.id)
      );
    }
  },
  computed: {
    ...mapState("AdminStore", ["shop", "shop_id"]),
    ...mapState("SuperAdminStore", ["brands", "shops"]),
    is_shown: {
      set(v) {
        if (!v) this.$emit("close");
      },
      get() {
        return this.show_dialog;
      },
    },
  },
  methods: {
    async update_shares_products_with() {
      await db
        .collection("shops")
        .doc(this.shop_id)
        .update({
          shares_products_with: Object.fromEntries(
            this.selected_shops.map((shop) => [shop.id, true])
          ),
        });
      this.is_shown = false;
    },
  },
};
</script>
