<template>
  <v-dialog v-model="show_dialog" width="600" @click:outside="is_shown = false">
    <v-card width="600px">
      <v-container class="pa-8">
        <p class="text-h3 mb-4 font-weight-bold">Brand Association</p>
        <v-alert
          type="info"
          colored-border
          border="left"
          color="primary"
          class="mb-4"
          >Choose with which brands shop {{ shop.name }} should be associated by
          clicking relevant checkbox.</v-alert
        >
        <v-text-field
          v-model="search"
          label="Name"
          placeholder="Filter by name"
          class="mb-8"
          single-line
          hide-details
          prepend-inner-icon="search"
        />
        <div class="brand-list">
          <template v-if="!_brands.length">
            <p>Sorry we there is no matches for your query</p>
          </template>
          <template v-else>
            <div class="d-flex mb-3" v-for="brand in _brands" :key="brand.id">
              <v-simple-checkbox
                :ripple="false"
                color="primary"
                :value="
                  shop.brands.find((b) => b.id === brand.id) ? true : false
                "
                @input="toggle_brand_association(brand.id)"
              >
              </v-simple-checkbox>
              <span class="text-h4 font-weight-bold ml-2"
                >{{ brand.name }}
              </span>
              &nbsp; ({{ brand.brand_type }})
            </div>
          </template>
        </div>
      </v-container>
      <v-card-actions class="px-8 pb-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded class="mr-0" @click="is_shown = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { db } from "@/db";

import { is_not_empty_array } from "@/utils/form_val_rules";
import {
  associate_shop_with_brand,
  dissociate_shop_from_brand,
} from "@/requests";

export default {
  name: "BrandsAdmin",
  data() {
    return {
      selected_brands: [],
      is_valid: false,
      is_not_empty_array,
      search: null,
    };
  },
  props: {
    shop_brands: {},
    show_dialog: {
      default: false,
      type: Boolean,
    },
    brands: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("ShopStore", ["shop"]),
    is_shown: {
      set(v) {
        if (!v) this.$emit("close");
      },
      get() {
        return this.show_dialog;
      },
    },
    _brands() {
      let search = this.search;
      if (!search) return this.brands;
      return this.brands.filter((b) => b.name.toLowerCase().includes(search));
    },
  },
  methods: {
    ...mapActions("ShopStore", ["get_shop"]),
    async toggle_brand_association(brand_id) {
      const request = this.shop.brands.find((b) => b.id === brand_id)
        ? dissociate_shop_from_brand
        : associate_shop_with_brand;
      await request(brand_id, this.shop_id);
      await this.get_shop(this.shop_id);
    },
    async update_brands() {
      await db
        .collection("shops")
        .doc(this.shop_id)
        .update({
          brand: Object.fromEntries(
            this.selected_brands.map((b_id) => [b_id, true])
          ),
        });
      this.is_shown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
